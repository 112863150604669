import styled from "@emotion/styled";
import Select from "../../../UI/Form/Select/Select";

import TextArea from "../../../UI/Form/Textarea/Textarea";
import Switcher from "../../../UI/Switcher/Switcher";
import {
  dayOfWeekOptions,
  frequencyOptions,
  FrequencyValues,
  hoursOptions,
  minutesOptions,
} from "../components/automationHelper";
import Input from "../../../UI/Form/Input/Input";
import Flex from "../../../UI/Flex/Flex";

const Container = styled.div`
  width: 100%;
`;

export default function Frequency({
  setReport,
  frequency,
  active,
  message,
  day,
  time,
  type = "excel",
}) {
  function setCorrectDayOfMonth(e) {
    const val = +e.target.value;
    if (val > 0 && val <= 31) {
      setReport((prev) => ({ ...prev, day: val }));
    }
  }

  function setFrequency(option) {
    setReport((prev) => ({
      ...prev,
      frequency: option,
      day: null,
      hour: null,
    }));
  }

  return (
    <Container>
      <div style={{ margin: "40px 0px" }}>
        <Select
          options={frequencyOptions}
          value={frequency}
          label="Frequency"
          onChange={setFrequency}
        />
      </div>

      {frequency?.value === FrequencyValues.WEEKLY && (
        <div style={{ margin: "40px 0px" }}>
          <Select
            label="Day of Week"
            options={dayOfWeekOptions}
            value={day}
            onChange={(option) =>
              setReport((prev) => ({ ...prev, day: option }))
            }
          />
        </div>
      )}

      {frequency?.value === FrequencyValues.MONTHLY && (
        <div style={{ margin: "40px 0px" }}>
          <Input
            label="Day of Month"
            type="number"
            min="1"
            max="31"
            value={day}
            onChange={setCorrectDayOfMonth}
          />
        </div>
      )}

      {frequency?.value !== FrequencyValues.UNSCHEDULED && (
        <div style={{ margin: "40px 0px" }}>
          <Flex alignItems="end">
            <div style={{ flex: 1 }}>
              <Select
                options={hoursOptions}
                label="Time"
                simpleValue={time.hours}
                onChange={(option) =>
                  setReport((prev) => ({
                    ...prev,
                    time: { ...prev.time, hours: option.value },
                  }))
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select
                options={minutesOptions}
                simpleValue={time.minutes}
                onChange={(option) =>
                  setReport((prev) => ({
                    ...prev,
                    time: { ...prev.time, minutes: option.value },
                  }))
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select
                options={[
                  { value: false, label: "AM" },
                  { value: true, label: "PM" },
                ]}
                simpleValue={time.isPm}
                onChange={(option) =>
                  setReport((prev) => ({
                    ...prev,
                    time: { ...prev.time, isPm: option.value },
                  }))
                }
              />
            </div>
          </Flex>

          <div>
            <p style={{ fontSize: 12 }}>* Time is set to Eastern Time</p>
          </div>
        </div>
      )}

      <div style={{ margin: "40px 0px" }}>
        <TextArea
          label="Message"
          value={message ?? ""}
          onChange={(e) =>
            setReport((prev) => ({ ...prev, message: e.target.value }))
          }
        />
      </div>

      <Select
        options={types}
        label="Type"
        simpleValue={type}
        onChange={(option) =>
          setReport((prev) => ({ ...prev, type: option.value }))
        }
      />

      <Switcher
        rightLabel="Active"
        checked={!!active}
        handleSwitchChange={() =>
          setReport((prev) => ({ ...prev, active: !active }))
        }
        containerStyle={{ marginTop: 40 }}
      />
    </Container>
  );
}

const types = [
  { value: "excel", label: "Excel" },
  { value: "pdf", label: "PDF", isDisabled: true },
];

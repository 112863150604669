import { dependencyFiltersMapping, monthNames } from "./constants/constants";
import { findBestMatch } from "string-similarity";

export const getDateFromMonthYear = (monthYear) => {
  if (!monthYear) {
    return;
  }

  const [month, year] = monthYear?.split(" ");

  return new Date(
    year,
    monthNames.findIndex((name) => name === month)
  );
};

export const getBands = (value) => {
  if (!value) {
    return;
  }

  const mappings = Object.keys(dependencyFiltersMapping);
  const { bestMatch } = findBestMatch(value, mappings);
  const index = mappings.indexOf(bestMatch.target);

  return index;
};

export function getEstTimezoneOffset(date = new Date(Date.now())) {
  const offsetString = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York",
    timeZoneName: "shortOffset",
  })
    .formatToParts(date)
    .filter((e) => e.type === "timeZoneName")[0].value;

  return Number(offsetString.replace(/^GMT\+?/, ""));
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGroup from "../../../UI/ButtonGroup/ButtonGroup";
import Flex from "../../../UI/Flex/Flex";
import IOButton from "../../../UI/Form/Button/IOButton";
import { previewEmail } from "../../../store/actions/automation";
import styled from "@emotion/styled";
import { getDisabledSubmitStatus } from "../components/automationHelper";

const InfoContainer = styled.div(
  ({ theme }) => `
  font-size: 13px;
  padding: 10px 20px;
  background: ${theme.notification.warningDark};
  color: white;
  margin-left: 40px;
  border-radius: 6px;
  svg {
    margin-right: 8px;
    font-size: 14px;
  }
`
);

export default function FooterButtons({
  onSave,
  saving,
  content,
  frequency,
  reportName,
  uuid,
  day,
  time,
  dispatch,
  previewLoading,
  convertedReport,
  report,
}) {
  const disabledMessage = getDisabledSubmitStatus({
    frequency,
    content,
    reportName,
    day,
    time,
    report,
    convertedReport,
  });

  return (
    <Flex direction="column" alignItems="start">
      {uuid && (
        <Flex>
          <IOButton
            outline
            icon={["fas", "envelope"]}
            onClick={() => dispatch(previewEmail(uuid))}
            style={{ marginLeft: 20, width: 240 }}
            processing={previewLoading}
          >
            Preview Email
          </IOButton>

          {!disabledMessage ? (
            <InfoContainer>
              <FontAwesomeIcon icon={["fal", "info-circle"]} />
              Save settings to apply your changes to the email preview
            </InfoContainer>
          ) : null}
        </Flex>
      )}
      <ButtonGroup spacing={20} style={{ marginTop: 20, width: "fit-content" }}>
        <IOButton cancel to="/admin/automation/scheduled-emails">
          Back
        </IOButton>
        <IOButton
          onClick={onSave}
          disabled={!!disabledMessage}
          title={disabledMessage}
          processing={saving}
        >
          Save
        </IOButton>
      </ButtonGroup>
    </Flex>
  );
}

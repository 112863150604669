import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../../UI/Flex/Flex";
import ChartPreview from "./ChartPreview";
import Filtering from "./Filtering";
import {
  buildContentOptions,
  getBlocks,
  getChartVisualization,
  getConvertedReport,
  getGeneralPayload,
} from "../components/automationHelper";
import FooterButtons from "./FooterButtons";
import Recipients from "./Recipients";
import { useParams, useHistory } from "react-router-dom";
import { loadUserGroups, loadUserList } from "../../../store/actions";
import Frequency from "./Frequency";
import {
  clearReportedQuery,
  createReportedQuery,
  getReportedQuery,
} from "../../../store/actions/automation";
import { isEmpty } from "lodash-es";
import Loading from "../../../UI/Loading/Loading";
import { themeGrayIf } from "../../../styles/styledSnippets";
import isEqual from "react-fast-compare";
import SyncTableConfig from "./SyncTableConfig";
import Content from "./Content";
import RecipientGroups from "./RecipientGroups";
import TriggerOnEtlConfig from "./TriggerOnEtlConfig";

const Container = styled.div`
  padding-left: 216px;
  display: flex;
  width: 100%;
  height: 100%;
  & > hr {
    height: 100%;
    border-color: ${(props) => props.theme.divider};
  }
  color: ${(props) => props.theme.text.secondary};
  @media (max-width: 1199px) {
    padding-left: 12px;
  }
`;

const PreviewLink = styled.div`
  cursor: pointer;
  border: 1px solid ${themeGrayIf(700, 400)};
  padding: 8px 18px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 30px;
  width: fit-content;

  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.4;
  `};
`;

const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 150px);
  padding-right: 18px;
  width: calc(100vw - 710px);
  @media (max-width: 1199px) {
    width: calc(100vw - 350px);
  }
`;

export default function ScheduleDetails() {
  const pages = useSelector((state) => state.pageManagement.pages);
  const { users, groups } = useSelector((state) => state.userManagement);
  const {
    reportedQuery,
    error,
    previewLoading,
    recipientLoading,
    recipientGroupLoading,
  } = useSelector((state) => state.automation);

  const { uuid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [report, setReport] = useState({});
  const [content, setContent] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [resetReport, setResetReport] = useState(false);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [saving, setSaving] = useState(false);

  const { blocks } = getBlocks(pages);
  const contentOptions = buildContentOptions(blocks);
  const convertedReport = getConvertedReport(uuid ? reportedQuery : null);

  const chart = getChartVisualization(blocks, content, report);

  useEffect(() => {
    dispatch(loadUserList());
    dispatch(loadUserGroups());
    if (uuid) {
      dispatch(getReportedQuery(uuid));
    }
  }, [dispatch, uuid]);

  // update attachments from new api response only after update
  useEffect(() => {
    if (reportedQuery && uuid) {
      setResetReport(true);
    }
  }, [reportedQuery, uuid]);

  const needUpdateReport = useMemo(() => {
    if (report && convertedReport) {
      return (
        !isEqual(report.recipients, convertedReport.recipients) ||
        !isEqual(report.recipientGroups, convertedReport.recipientGroups) ||
        resetReport // need reset or not
      );
    }
  }, [report, convertedReport, resetReport]);

  useEffect(() => {
    if (needUpdateReport) {
      setReport((report) => ({
        ...report,
        recipients: convertedReport.recipients,
        recipientGroups: convertedReport.recipientGroups,
        attachments: convertedReport.attachments,
        active: convertedReport.active,
        updatedAt: convertedReport.updatedAt,
      }));
      setResetReport(false);
      setRefreshFilters(true);
    }
  }, [convertedReport, needUpdateReport]);

  useEffect(() => {
    if (convertedReport && isEmpty(report)) {
      const content = contentOptions.find(
        (option) => option.value === convertedReport.visualizationUuid
      );
      setContent(content);
      setReport(convertedReport);
    }
  }, [contentOptions, convertedReport, report]);

  useEffect(() => {
    return () => {
      dispatch(clearReportedQuery());
    };
  }, [dispatch]);

  async function createQueryReport() {
    const payload = getGeneralPayload({
      ...report,
      chart,
      content,
      uuid,
    });

    try {
      setSaving(true);
      await dispatch(createReportedQuery(payload, uuid, history));
    } finally {
      setSaving(false);
    }
  }

  if (uuid && (!convertedReport || !chart) && !error) {
    return <Loading />;
  }

  if (error) {
    return <Container>Something went wrong, please try again.</Container>;
  }

  return (
    <>
      <Container>
        <FlexCenter>
          <div style={{ maxWidth: 700 }}>
            <Content
              report={report}
              setReport={setReport}
              contentOptions={contentOptions}
              setContent={setContent}
              chart={chart}
              content={content}
              convertedReport={convertedReport}
            />

            <SyncTableConfig
              chart={chart}
              report={report}
              setReport={setReport}
              content={content}
              setRefreshFilters={setRefreshFilters}
            />
            <PreviewLink
              onClick={() => setShowPreview(true)}
              disabled={!content}
            >
              Preview source table
            </PreviewLink>
            <Filtering
              setReport={setReport}
              chart={chart}
              refreshFilters={refreshFilters}
              setRefreshFilters={setRefreshFilters}
              key={chart?.visualizationId}
            />
          </div>
          <FooterButtons
            onSave={createQueryReport}
            saving={saving}
            content={content}
            frequency={report.frequency}
            reportName={report.name}
            uuid={uuid}
            day={report.day}
            time={report.time}
            dispatch={dispatch}
            previewLoading={previewLoading}
            report={report}
            convertedReport={convertedReport}
          />
        </FlexCenter>
        <hr />

        <div
          style={{
            width: 320,
            paddingLeft: 18,
            flexShrink: 0,
            paddingRight: 8,
          }}
        >
          <Flex direction="column" style={{ width: "100%" }}>
            <>
              {uuid && (
                <>
                  <Recipients
                    users={users}
                    recipients={report.recipients}
                    dispatch={dispatch}
                    uuid={uuid}
                    recipientLoading={recipientLoading}
                  />
                  <RecipientGroups
                    all={groups}
                    groups={report.recipientGroups}
                    dispatch={dispatch}
                    uuid={uuid}
                    recipientGroupLoading={recipientGroupLoading}
                  />
                </>
              )}
              <TriggerOnEtlConfig
                triggerEtlConfigDefaultName={report.triggerEtlConfigDefaultName}
                triggerEtlConfigUuid={report.triggerEtlConfigUuid}
                setTriggerEtlConfigUuid={(triggerEtlConfigUuid) =>
                  setReport((old) => ({ ...old, triggerEtlConfigUuid }))
                }
              />
              <Frequency
                frequency={report.frequency}
                setReport={setReport}
                active={report.active}
                message={report.message}
                day={report.day}
                time={report.time}
                type={report.type}
              />
            </>
          </Flex>
        </div>
      </Container>
      <ChartPreview
        setShowPreview={setShowPreview}
        chart={chart}
        showPreview={showPreview}
      />
    </>
  );
}

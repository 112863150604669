import React, { useEffect, useState, useMemo } from "react";
import Input from "../../../UI/Form/Input/Input";
import IOButton from "../../../UI/Form/Button/IOButton";

import styled from "@emotion/styled";
import Select from "../../../UI/Form/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { loadConnectionTables } from "../../../store/actions";
import ActiveTableJoinedMode from "./ActiveTableJoinedMode";

const Instructions = styled.div`
  color: ${(props) => props.theme.text.primary};
  max-width: 700px;
  margin-bottom: 24px;
`;
export default function ActiveTableNewTableForm(props) {
  const {
    activeTable,
    handleSubmit,
    newTableName,
    setNewTableName,
    existing,
    isProcessing,
    activeTables,
  } = props;

  const [joinedMode, setJoinedMode] = useState({
    mode: true,
    viewName: "",
    activeTableName: "",
    columnToJoinName: "",
    columnToJoinType: "",
    namespace: "dbo",
  });

  const dispatch = useDispatch();
  const { tables, columnsLoading, tableColumns, loading } = useSelector(
    (state) => state.connections
  );

  const tableOptions = tables
    .filter((t) => ["dbo", "dw", "etl"].includes(t.namespace))
    // you cant use created views tables for joined mode
    .filter((t) => (joinedMode.mode ? t.type !== "view" : true))
    .filter((t) => !activeTables.some((at) => at.tbl === t.name))
    .map(({ name, ...rest }) => ({ ...rest, value: name, label: name }));

  function handleSelectTable(option) {
    // call dispatch/setState only when table name changed
    if (option.value !== newTableName) {
      setNewTableName(option.value);
      dispatch(
        actions.getTableDataByTableName(`${option.namespace}.${option.value}`)
      );
      setJoinedMode((prev) => ({
        ...prev,
        activeTableName: `at_joined_${option.value}`,
        viewName: `at_output_${option.value}`,
        namespace: option.namespace,
      }));
    }
  }

  const connections = useSelector((state) => {
    return state.connections.list;
  });

  const defaultConnection = useMemo(() => {
    return connections.find((v) => v.primaryWarehouse);
  }, [connections]);

  useEffect(() => {
    if (!defaultConnection) {
      return;
    }
    dispatch(
      loadConnectionTables(defaultConnection.uuid, defaultConnection.database)
    );
    dispatch(actions.listActiveTables());
  }, [defaultConnection, dispatch]);

  const isActiveTableExist = tables.some(
    (table) => table.name === joinedMode.activeTableName
  );

  const isViewExist = tables.some(
    (table) => table.name === joinedMode.viewName
  );

  function checkJoinModeOptions() {
    return joinedMode.mode
      ? !Object.values(joinedMode).every(Boolean) || isActiveTableExist
      : false;
  }

  const joinModeValidation = checkJoinModeOptions();

  if (existing)
    return (
      <div data-cy="select-existing-table-block">
        <Instructions>
          Select a table to use as a base for this. If this table is currently
          being updated via an ETL process currently, there may need to be
          changes made.
        </Instructions>
        <div style={{ maxWidth: 400 }}>
          <Select
            cy="existing-tables"
            options={tableOptions}
            onChange={handleSelectTable}
            value={{ value: newTableName, label: newTableName }}
            isLoading={loading}
          />
        </div>

        <ActiveTableJoinedMode
          joinedMode={joinedMode}
          setJoinedMode={setJoinedMode}
          tableColumns={tableColumns}
          columnsLoading={columnsLoading}
          isActiveTableExist={isActiveTableExist}
          isViewExist={isViewExist}
        />

        <IOButton
          cy="submit-new-active-table"
          onClick={() => handleSubmit(joinedMode)}
          disabled={!newTableName || isProcessing || joinModeValidation}
          style={{ marginTop: 24 }}
          processing={isProcessing}
        >
          Create Table
        </IOButton>
      </div>
    );

  return (
    <div>
      <Instructions>
        Name your table. This will come in handy when you're accessing these
        records in data management.
      </Instructions>
      {activeTable.errorMessage ? (
        <div style={{ marginBottom: 24 }}>
          ERROR: {activeTable.errorMessage}
        </div>
      ) : null}
      <Input
        cy="enter-new-table-name"
        value={newTableName}
        onChange={(e) => setNewTableName(e.target.value)}
        placeholder="my-new-table..."
      />
      <IOButton
        cy="submit-new-active-table"
        onClick={handleSubmit}
        disabled={!newTableName || isProcessing}
        processing={isProcessing}
        style={{ marginLeft: 12 }}
      >
        Create Table
      </IOButton>
    </div>
  );
}

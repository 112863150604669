import Select from "../../../UI/Form/Select/Select";
import { useDispatch } from "react-redux";
import { loadEtlConfigs } from "../../../store/actions";
import { useEffect, useMemo } from "react";
import { useShallowEqualSelector } from "../../../store";

export default function TriggerOnEtlConfig({
  setTriggerEtlConfigUuid,
  triggerEtlConfigUuid,
  triggerEtlConfigDefaultName,
}) {
  const dispatch = useDispatch();
  const { etlConfigs, loading } = useShallowEqualSelector(
    (state) => state.etlConfigs
  );

  const options = useMemo(() => {
    return [
      { value: null, label: <em>(No trigger)</em> },
      ...(etlConfigs ?? []).map((v) => ({ value: v.uuid, label: v.name })),
    ];
  }, [etlConfigs]);

  useEffect(() => {
    dispatch(loadEtlConfigs());
  }, [dispatch]);

  const selectedValue = useMemo(() => {
    return (
      options?.find((v) => v.value === triggerEtlConfigUuid) ?? {
        value: triggerEtlConfigUuid,
        label: triggerEtlConfigDefaultName,
      }
    );
  }, [options, triggerEtlConfigDefaultName, triggerEtlConfigUuid]);

  return (
    <div style={{ margin: "40px 0px" }}>
      <Select
        label="Trigger on ETL success"
        tooltip="Your email will be sent each time new data is is loaded from the selected configuration."
        options={options}
        value={selectedValue}
        onChange={(v) => setTriggerEtlConfigUuid(v.value)}
        isLoading={loading}
      ></Select>
    </div>
  );
}
